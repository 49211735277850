import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { resetPassword as resetPasswordApiCall } from "../api";
import Password from "../components/Password";
import { useAppContext } from "../context/AppContext";

export default function Reset() {
  const {
    state: {
      dictionary: { reset: contentfulData },
    },
    actions: { setMessage },
  } = useAppContext();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const { passwordResetKey, repDID, customerDID } = params;
  let navigate = useNavigate();

  const [password1, set_password1] = useState("");
  const [password2, set_password2] = useState("");
  const [password1IsValid, set_password1IsValid] = useState(false);
  const [password2IsValid, set_password2IsValid] = useState(false);
  const [formIsValid, set_formIsValid] = useState(false);
  const [
    text_resetPassword,
    text_pleaseFillOutForm,
    text_successMessage,
    text_failureMessage,
  ] = contentfulData;

  useEffect(() => {
    if (password1 === password2) set_password2IsValid(true);
    else set_password2IsValid(false);
  }, [password1, password2]);

  useEffect(() => {
    if (password1IsValid && password2IsValid) set_formIsValid(true);
    else set_formIsValid(false);
  }, [password1IsValid, password2IsValid]);

  function valueUpdate(newValue) {
    set_password1(newValue);
  }
  function handleConfirmPasswordChange(e) {
    const { value } = e.target;
    set_password2(value);
  }
  function resetPassword() {
    resetPasswordApiCall(repDID, customerDID, passwordResetKey, password1)
      .then((response) => {
        setMessage({ text: text_successMessage, type: "success" });
      })
      .catch(() => {
        setMessage({ text: text_failureMessage });
      })
      .finally(() => navigate("/login"));
  }

  return (
    <section>
      <h1 className="text-center mb-40">{text_resetPassword}</h1>
      <div className="form-max flex-20 column">
        <p>{text_pleaseFillOutForm}</p>

        <Password
          isValid={set_password1IsValid}
          onChange={valueUpdate}
          value={password1}
        />

        <ConfirmPassword onChange={handleConfirmPasswordChange} />

        <div className="flex-20 mt-40 justify-center">
          <button disabled={!formIsValid} type="button" onClick={resetPassword}>
            {text_resetPassword}
          </button>
        </div>
      </div>
    </section>
  );
}
function ConfirmPassword(props) {
  const {
    state: {
      dictionary: { password: contentfulData },
    },
  } = useAppContext();

  const { onChange } = props;
  const [hidden, set_hidden] = useState(true);
  const [text_password, text_show, text_hide] = contentfulData;
  return (
    <div className="password-wrapper">
      <div className="input-wrapper">
        <input
          id="password"
          name="password"
          type={hidden ? "password" : "text"}
          required
          onChange={onChange}
        />
        <label htmlFor="password" aria-label="password">
          {text_password}
        </label>
        <button
          type="button"
          id="passwordShow"
          onClick={(e) => set_hidden(!hidden)}
        >
          {hidden ? text_show : text_hide}
        </button>
      </div>
    </div>
  );
}
